import { CartItemsAddedResultEventData, CartItemsRemovedResultEventData, CartItemsResultEventData } from "../../common/pubsub/models/cart-items-result-event-data.js";
import { IDetailedCartInfo } from "./interfaces/detailed-cart-info.js";
import { cartEventPublisher } from "../../common/pubsub/cart-event-pub-sub.js";
import { ImageMode } from "../../common/interfaces/image-mode.js";
import { EventActionResult } from "../../common/pubsub/models/event-data-base.js";
import { RemoveFromCartItemContract } from "../../common/public-estore/remove-from-cart-contract.js";
import { UpdateCartQuantityItemContract } from "../../common/public-estore/update-cart-quantity-contract.js";
import { Notify } from "../../../components-shared/utils/notify.js";

export class CartService {
    private static instance: CartService | null = null;

    constructor() {
        if (!CartService.instance) {
            CartService.instance = this;
        }        

        this._detailedCartData = {
            couponCodes: [],
            products: [],
            summary: null,
            freeShippingInfo: null,
        }

        this._siteCartData = {
            couponCodes: [],
            products: [],
            summary: null,
            freeShippingInfo: null,
        }
        return CartService.instance as CartService;
    }

    private _getDetailedCartInfo: Promise<IDetailedCartInfo>;
    private _getSiteCartInfo: Promise<IDetailedCartInfo>;
    private _isPrivateAction:boolean = true; // What is it? Developers told that it requried to be checked, could be useless.
    private _includeVat:boolean = window.globalSettings.displayPricesIncludingVat;
    private _detailedCartData: IDetailedCartInfo;
    private _siteCartData: IDetailedCartInfo;
    private _notify = new Notify(); 

    private _showErrorMessage(errorMessage: string){
        this._notify.error(errorMessage);
    }    
    
    /* #region Public */
    public async emptyCart() : Promise<IDetailedCartInfo>{
        const clearCartEventData = await window.PublicEStore.emptyCart(this._isPrivateAction).then((data) => data);

        if (clearCartEventData.status === EventActionResult.Ok) {
            cartEventPublisher.cartEmptied(clearCartEventData);
        }

        return clearCartEventData.result.detailedCartInfo;
    }

    public async detailedCartInfo(_includeVat:boolean = true, _isPrivateAction:boolean = true, imageWidth:number = 64) : Promise<IDetailedCartInfo>{
        if (!this._getDetailedCartInfo) {
            this._getDetailedCartInfo = new Promise<IDetailedCartInfo>((resolve, reject)=>{
                // The line below returns cache result is one more request fired. 
                // Resolves the issue when webcomponent based on CartController included in the component based on CartController, ex: free-shipping-progress-bar in cart-sidebar-v1
                // Creates an issue if timed out request needs to be fired again, then resetDetailedCartInfoCache() should be called before forced request.
                // Probably can be done better.
                this._detailedCartData.summary ? resolve(this._detailedCartData) : 
                window.PublicEStore
                    .getDetailedCartInfo(_includeVat, _isPrivateAction, imageWidth, ImageMode.Pad)
                    .then((data) => {
                        if(data.status === EventActionResult.Ok){
                            this._detailedCartData = data.result.detailedCartInfo;
                            resolve(this._detailedCartData);
                        } else {
                            reject(data);
                        }
                        this._getDetailedCartInfo = null;
                    }, (error) => {
                        reject(error)
                        this._getDetailedCartInfo = null;
                    })
            });
        }
        return this._getDetailedCartInfo;
    }

    public async getSiteCartInfo(_includeVat:boolean = true, _isPrivateAction:boolean = true, imageWidth:number = 60) : Promise<IDetailedCartInfo>{
        if (!this._getSiteCartInfo) {
            this._getSiteCartInfo = new Promise<IDetailedCartInfo>((resolve, reject)=>{
                // The line below returns cache result is one more request fired. 
                // Resolves the issue when webcomponent based on CartController included in the component based on CartController, ex: free-shipping-progress-bar in cart-sidebar-v1
                // Creates an issue if timed out request needs to be fired again, then resetDetailedCartInfoCache() should be called before forced request.
                // Probably can be done better.
                this._siteCartData.summary ? resolve(this._siteCartData) : 
                window.PublicEStore
                    .getSiteCartInfo(_includeVat, _isPrivateAction, imageWidth, ImageMode.Pad)
                    .then((data) => {
                        if(data.status === EventActionResult.Ok){
                            this._siteCartData = data.result.detailedCartInfo;
                            resolve(this._siteCartData);
                        } else {
                            reject(data);
                        }
                        this._getSiteCartInfo = null;
                    }, (error) => {
                        reject(error)
                        this._getSiteCartInfo = null;
                    })
            });
        }
        return this._getSiteCartInfo;
    }

    public async removeFromCart(tempOrderLineID: number) : Promise<CartItemsRemovedResultEventData> {
        try{
            const removeFromCartContract = {
                tempOrderLineID: tempOrderLineID,
            } as RemoveFromCartItemContract;

            const removeFromCartEventData = await window.PublicEStore.removeFromCart(removeFromCartContract, this._isPrivateAction).then((data) => data);

            if (removeFromCartEventData.result) {
                cartEventPublisher.cartItemsRemoved(removeFromCartEventData);
            }

            return removeFromCartEventData;

        } catch (error){
            throw error;
        }
    }

    public async addToCart(productId: number, quantity: number) : Promise<CartItemsAddedResultEventData>{
        const addToCartContract = {
            productId: productId,
            //imageId: $prodContainer.data(dataNames.imageId),
            quantity: quantity,
            //fraction: $prodContainer.find('.' + classes.fractionInput).val(),
            //customFields: customFields
        };

        const addToCartEventData = await window.PublicEStore.addToCart(addToCartContract, this._includeVat, this._isPrivateAction);

        return addToCartEventData;
    }

    public async updateCartQuantity(productId: number, quantity: number, productID: number) : Promise<CartItemsResultEventData>{
        const udpateCartQuantityContract = {
            tempOrderLineID: productId,
            quantity: quantity,
            productID: productID,
        } as UpdateCartQuantityItemContract;

        const updateCartEventData = await window.PublicEStore.updateCartQuantity(udpateCartQuantityContract, this._isPrivateAction);

        if (updateCartEventData.result) {
            cartEventPublisher.cartQuantityUpdated(updateCartEventData);
        }

        return updateCartEventData;
    }

    public resetDetailedCartInfoCache() {
        this._getDetailedCartInfo = null;
        this._detailedCartData.summary = null;
    }

    public resetSiteCartInfoCache() {
        this._getSiteCartInfo = null;
        this._siteCartData.summary = null;
    }

    public async createOffer() {
        const requestInit = {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json"
            }
        } as RequestInit;

        const response = await fetch(`/api/shopping-cart/create-offer`, requestInit);
        const result = await response.json();

        if (!response.ok) {
            const errorMessage = result.message ? result.message : result.Message ? result.Message : "An error occurred while creating the offer";
            this._showErrorMessage(errorMessage);
            return;
        }

        if (result.success && result.offerId) {
            window.location.href = "/Startup/Common/Default.aspx?offerId="+result.offerId;
        }
    }

    /* #endregion */
}